var swipe = {
  init: function () {
    
    var swiperEventsHome = new Swiper('.slide-hero', {
      loop: true,
      autoplay: {
        delay: 3500,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      slidesPerView: 1, 
    }); 
    
  }
};

swipe.init();